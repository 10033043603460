<!--运行维护-移机变更记录-->
<template>
<section>
  <!-- 工具条 -->
  <el-col :span='24' class='toolbar' style='padding-bottom: 0px;'>
  <el-form :inline='true' :model='filter' size='mini'>
    <el-form-item>
      <el-input v-model='filter.name' placeholder='设备编码搜索'>
        <el-button slot='prepend' icon='el-icon-refresh' @click='handleRefresh'></el-button>
        <el-button slot='append' icon='el-icon-search' @click='getData'></el-button>
      </el-input>
    </el-form-item>
    <el-form-item>
      <el-select
        class="left-margin"
        placeholder='选择监测点'
        @change="getData"
        v-model.trim='filter.localeId'
        filterable
        clearable>
        <el-option
          v-for="item in locale"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id">
        </el-option>
      </el-select>
    </el-form-item>
    <!-- <el-form-item>
      <el-button type='primary' size='mini' @click='handleAdd'>新增</el-button>
    </el-form-item> -->
  </el-form>
  </el-col>
    <!-- 列表 -->
    <el-col :span='24'>
      <el-table :data='datalist.content' size='mini' border highlight-current-row v-loading='loading' :max-height='clientHeight' style='width: 100%'>
        <el-table-column type='selection' />
        <el-table-column type='index' label='#' align='center' width='55' />
        <el-table-column prop='DeviceMn' width='200' show-overflow-tooltip label='设备' align='center' header-align='center' />
        <el-table-column prop='BeginAt' width='140' :formatter="dateFormatter" label='启用日期' align='center' header-align='center' />
        <el-table-column prop='EndAt' width='140' :formatter="dateFormatter" label='停用日期' align='center' header-align='center' />
        <el-table-column prop='CurrentCustomerName' label='当前绑定单位' show-overflow-tooltip header-align='center' />
        <el-table-column prop='CurrentLocaleName' show-overflow-tooltip label='当前绑定监测点' header-align='center' />
        <!-- <el-table-column prop='OldOwner' label='上次绑定单位' width='300' show-overflow-tooltip header-align='center' />
        <el-table-column prop='Pid' width='200' show-overflow-tooltip label='上次绑定监测点' header-align='center' /> -->
        <el-table-column prop='Binder' :formatter="userFormatter" label='绑定人' align='center' header-align='center' />
        <el-table-column label='操作' align='center' header-align='center'>
          <template slot-scope='scope'>
            <!-- <i title='编辑' @click='handleEdit(scope.$index, scope.row)' class='opt el-icon-edit' /> -->
            <el-button type="text" @click="handleClick(scope.row)" size="mini">变更记录</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination small background @size-change="(v)=>handlePageChange(v, 'size', false)" @current-change="(v)=>handlePageChange(v, 'page', false)" :current-page.sync='filter.page' :page-sizes='filter.pageSizes' layout='total, sizes, prev, pager, next, jumper' :total='datalist.total' style='display:inline-block;margin-left:15px' />
    </el-col>

    <!--编辑界面-->
    <!-- <el-dialog :title='dlg.title' :visible.sync='dlg.visible' @close='handlerClose'>
      <div slot='footer' class='dialog-footer'>
        <el-button size='mini' @click.native='dlg.visible=false'>取消</el-button>
        <el-button type='primary' size='mini' @click.native='handleSubmit' >提交</el-button>
      </div>
    </el-dialog> -->
    <!-- 变更记录 -->
    <el-dialog
      title="变更记录"
      :visible.sync="recordVisible"
      width="800px"
    >
      <el-table
        :data="recordList"
        size="mini"
        border
        highlight-current-row
        :max-height="clientHeight - 40"
        style="width: 100%"
      >
        <el-table-column
          prop="PrevCustomerName"
          label="历史绑定单位"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="PrevLocaleName"
          label="历史绑定监测点"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="BeginAt"
          :formatter="dateFormatter"
          label="启用日期"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="EndAt"
          :formatter="dateFormatter"
          label="变更日期"
          align="center"
          header-align="center"
        />
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click.native="recordVisible = false"
          >关闭</el-button
        >
      </div>
    </el-dialog>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { dateFormater, dataFormatter } from '@/util/index'

export default {
  data() {
    return {
      changeState: false,
      index: 0, // 记录更新index
      sels: [], // 列表选中列
      loading: false,
      datalist: {},
      dlg: {
        visible: false,
        title: ''
      },
      filter: {
        name: null,
        localeId: null,
        field: 'name',
        page: 1,
        size: 20,
        pageSizes: [20, 30, 50]
      },
      recordVisible: false,
      recordList: [],
      formData: {},
      formRule: {
        Name: [{ required: true, message: '名称不可为空', trigger: 'blur' }]
      }
    }
  },
  computed: {
    ...mapState(['props', 'clientHeight', 'customerTree', 'user', 'locale']),
    ...mapState({
      orgProps: state => Object.assign({}, state.props, { label: 'Org', checkStrictly: true })
    })
  },
  created() {
    this.getData()
  },
  methods: {
    hasChange() {
      this.changeState = true
    },
    getData: function() {
      this.loading = true
      var para = { StartAt: (this.filter.page - 1) * this.filter.size, Size: this.filter.size }
      para.Param = {}
      if (this.filter.name) {
        para.Param['mn'] = this.filter.name
      }
      if (this.filter.localeId) {
        para.Param['Locale_Id'] = this.filter.localeId
      }
      this.$post('admin/listLocaleDeviceRecord', para).then(res => {
        this.datalist = res
        this.loading = false
      })
    },
    handleClick(row) {
      this.recordVisible = true;
      var para = {"StartAt":0,"Size":20}
      para.Param = {}
      para.Param['device_id'] = row.DeviceId
      this.$post('admin/listLocaleDeviceRecord', para).then(res => {
        this.recordList = res.content
      })
    },
    userFormatter(r, c) {
      return dataFormatter(r[c.property], this.user)
    },
    dateFormatter(r, c) {
      return dateFormater(r[c.property], true)
    },
    handleLocaleChange() {
      const node = this.$refs['localeCascader'].getCheckedNodes()
      if (node[0]) {
        if (typeof node[0].data.Id !== 'string') {
          this.$message.warning(`请选择监测点节点`)
          this.filter.localeId = null
          return
        }
        this.filter.localeId = node[0].data.Id
        this.getData()
      }
    },
    handleRefresh() {
      this.filter.page = 1
      this.filter.name = null
      this.filter.localeId = null
      this.getData()
    },
    selsChange: function(sels) {
      this.sels = sels
    },
    handlePageChange(val, field) {
      this.filter[field] = val
      this.getData()
    }
    // handleAdd: function() {
    //   this.dlg.title = '新增'
    //   this.dlg.visible = true
    //   this.formData = {}
    // },
    // handleEdit: function(index, row) {
    //   this.dlg.title = '编辑'
    //   this.dlg.visible = true
    //   this.index = index
    //   this.formData = Object.assign({}, row)
    // },
    // handleSubmit: function() {
    //   if (!this.changeState) {
    //     this.dlg.visible = false
    //     return
    //   }
    //   this.$refs.form.validate(valid => {
    //     if (valid) {
    //       this.changeState = false
    //       this.$confirm('确认提交吗？', '提示', {}).then(() => {
    //         const para = Object.assign({}, this.formData)
    //         this.$post('admin/saveLocaleDeviceRecord', para).then(() => {
    //           this.handleRefresh()
    //           if (this.$refs['form']) {
    //             this.$refs['form'].resetFields()
    //           }
    //           this.dlg.visible = false
    //         }).catch(() => {})
    //       }).catch(() => {})
    //     }
    //   })
    // },
    // handlerClose: function() {
    //   this.dlg.visible = false
    //   if (this.$refs['form']) {
    //     this.$refs['form'].resetFields()
    //   }
    // }
  }
}
</script>
